import React, { useState, useContext } from "react";
import { Theme } from "../../../context/theme";

import "./product.css";
import Banner from "../../banner/banner";

const Product = (props) => {
  return (
    <div className="product">
      {/*                                */}
      <div className="left">
        <p className="name">{props.name}</p>
        <div className="price">
          <p>{props.price}</p>
        </div>
        <a href="tel:3780274">
          <button className="order">ZADZWOŃ</button>
        </a>
      </div>
      {/*                                */}
      <div className="description">{props.description}</div>
      {/*                                */}

      {/*                                */}
    </div>
  );
};

export const productsInfo = [
  {
    name: "EkoGroszek",
    angName: "ekogroszek",
    description:
      "Ekogroszek to wyjątkowy węgiel o doskonałych właściwościach. Dzięki bardzo wysokiej wartości opałowej zapewnia dużą wydajność przy małym zużyciu, a jego dodatkowymi zaletami są m. in. minimalna zawartość popiołu i siarki.",
  },
  {
    name: "Węgiel",
    angName: "Wegiel węgiel",
    description:
      "Węgiel najlepszej jakości tylko u nas. Gwarantowana duża wydajność i ciepło w domu. A wszystko to po okazyjnej cenie",
  },
  {
    name: "Pelet",
    angName: "pelet",
    description:
      "Pellet sosnowy to wysokiej jakości opał, który produkowany jest ze sprasowanych pod wysokim ciśnieniem trocin sosonowych, bez domieszek i dodatków chemicznych, mała zawartość popiołu i wilgoci. Produkt jest niezwykle przyjazny dla środowiska",
  },
  {
    name: "Cement",
    angName: "cement",
    description:
      "Cement świetnie sprawdzi się do małych elementów architektonicznych, stabilizacji gruntów przy budowie dróg, przygotowanie zapraw tynkarskich,  do produkcji betonu komórkowego, ",
  },
  {
    name: "Brykiet",
    angName: "brykiet",
    description:
      "Brykiet jest idealną alternatywą dla drewna, powstaje w wyniku sprasowania suchych trocin pod dużym ciśnieniem bez dodatku środków chemicznych. Idealnie nadaje się do kominków, kotłów c.o, oraz piecy kaflowych.",
  },
  {
    name: "Pasze",
    angName: "pasze pasza",
    description:
      "Najlepszej jakości pasze do karmienia swoich zwierząt gospodarskich. Gwarantujemy że zwierzaki będa zadowolone ",
  },
  {
    name: "Nawozy",
    angName: "nawozy",
    description:
      "Polecamy szeroka gamę różnorodnych środków służących poprawie wydajności upraw oraz obfitości plonów. Nowoczesne nawozy i odżywki znajdują powszechne zastosowanie we współczesnym rolnictwie oraz ogrodnictwie - zarówno na skalę przemysłową, jak również prywatną.  ",
  },
  {
    name: "Folie rolnicze",
    angName: "folie folia rolnicza rolnicze",
    description:
      "Nasze folie rolnicze wykonane są z najwyższej jakości materiału, który szczelnie chronią materiały przed kurzem, wilgocią czy innymi niekorzystnymi czynnikami.",
  },
  {
    name: "Środki ochrony roślin",
    angName: "srodki ochrony roslin środki roślin ",
    description:
      "W naszym sklepie oferujemy szeroki asortyment środków ochrony roślin do walki ze szkodnikami – chwastami, grzybami, owadami lub ślimakami. Precyzja w określeniu zagrożenia dla plonów jest kluczowa dla efektywności w korzystaniu z chemicznych środków ochrony roślin. ",
  },
];

const Cennik = () => {
  const { isDarkTheme, lightTheme, darkTheme, changeTheme } = useContext(Theme);
  const theme = isDarkTheme ? darkTheme : lightTheme;
  const [searchBar, setSearch] = useState("");

  function handleChange(e) {
    e.preventDefault();
    setSearch(e.target.value.toLowerCase());
  }

  const renderProducts = () => {
    var finalProds = "";
    if (searchBar) {
      finalProds = productsInfo
        .filter((prod) => prod.angName.toLowerCase().includes(searchBar))
        .map((filteredName) => (
          <Product
            name={filteredName.name}
            description={filteredName.description}
            imageLink={filteredName.imageLink}
            key={filteredName.name + `key`}
          />
        ));
    } else
      finalProds = productsInfo.map((filteredName) => (
        <Product
          name={filteredName.name}
          description={filteredName.description}
          imageLink={filteredName.imageLink}
          key={filteredName.name + `key`}
        />
      ));

    return finalProds;
  };

  return (
    <div style={{ backgroundColor: theme.background }}>
      <Banner name="Produkty" />
      <div
        className="products"
        // style={{ backgroundColor: theme.backgroundLight }}
      >
        <div className="flex">
          {" "}
          <input
            type="search"
            name=""
            id="search"
            onChange={handleChange}
            value={searchBar}
            placeholder="Szukaj produktów"
          />
        </div>

        <br />
        {renderProducts()}
        <div
          className="product text-center"
          style={{ paddingTop: 40, paddingBottom: 40 }}
        >
          Nie widzisz swojego produktu na liście? Zadzwoń pod numer stacjonarny
          aby uzyskać więcej informacji
        </div>
      </div>
    </div>
  );
};

export default Cennik;
