import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact">
        <div className="click">
          <a href="tel:513047070">Komórkowy 513 047 070</a>
        </div>
        <div className="click">
          <a href="tel:483780274">Stacjonarny 483 780 274</a>
        </div>
        <div className="click">
          <a
            href="https://goo.gl/maps/H6qXKvRksrwtMufR7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wola Solecka Pierwsza 175
          </a>
        </div>
      </div>
      <div className="onas">
        <div className="desc">
          Sklep spożywczo-przemysłowy. Węgiel, cement, nawozy, środki ochrony
          roślin itd. w najlepszej cenie
        </div>
      </div>
      <div className="hours">
        <div>Godziny otwarcia</div>
        <div>6:00-20:00</div>
      </div>
    </footer>
  );
};

export default Footer;
