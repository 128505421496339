import Banner from "../banner/banner";

const Gallery = () => {
  return (
    <div>
      <Banner name="Galeria" />
    </div>
  );
};

export default Gallery;
