import React, { useContext } from "react";
import "./home.css";
import { Theme } from "../context/theme";

import Siarkopol from "./siarkopol.png";
import Azoty from "./azoty.png";
import { productsInfo } from "./subpages/cennik/cennik";
import BoxProduct from "./boxproduct";
import { NavLink } from "react-router-dom";
import Banner from "./banner/banner";

const Home = () => {
  const { isDarkTheme, lightTheme, darkTheme } = useContext(Theme);
  const theme = isDarkTheme ? darkTheme : lightTheme;

  const renderProducts = () => {
    var finalProds = "";
    finalProds = productsInfo.map((filteredName) => (
      <BoxProduct
        name={filteredName.name}
        description={filteredName.description}
        imageLink={filteredName.imageLink}
        key={filteredName.name + `key`}
      />
    ));

    return finalProds;
  };

  return (
    <div
      style={{
        color: theme.text,
        backgroundColor: theme.background,
        fontSize: 20,
      }}
      className="home text-center"
    >
      {/* <div
        style={{
          color: "white",
        }}
        className="text-center background"
      >
        <h1 className="titleText">Sklep spożywczo-przemysłowy Wola Solecka</h1>
        <h3 className="midText">Otwarty dziś do 20:00</h3>
      </div> */}
      <Banner name="Sklep spożywczo-przemysłowy" sub="Otwarty do" />
      <br />
      <p style={{ fontSize: 24, fontWeight: 600 }}>
        Zapraszamy do naszego sklepu
      </p>
      <br />
      <p>
        Działamy na rynku 27 lat. W naszym sklepie oferujemy duży wybór:
        alkoholi, chemii, kosmetyków, mięsa oraz świeżych owoców i warzyw. U nas
        możesz kupić nawozy, węgiel, pelet, ekogroszek, wapno, cement, środki
        ochrony roślin, folie rolnicze i sznur.
      </p>
      <br />
      <p>
        Zapewniamy profesjonalną obsługę oraz transport do wywozów towarów
        masowych. Wykwalifikowana kadra zawsze pomoże i doradzi.
      </p>
      <br />
      <p>
        Wspieramy równiez klimat poprzez chętne zbieranie butelek szklanych po
        różnych napojach
      </p>
      <br />
      <br />
      <div>
        <p style={{ fontSize: 24, fontWeight: 600 }}>
          Główni dostawcy naszego zakładu:
        </p>
        <div className="suppliers">
          <div className="supplier">
            <img src={Siarkopol} alt="Siarkopol" />
            <p style={{ fontSize: 27 }}>Siarkopol</p>
          </div>
          <div className="supplier">
            <img src={Azoty} alt="Grupa Azoty" />
            <p style={{ fontSize: 27 }}>Grupa Azoty</p>
            {/* <p>Grupa Azoty Puławy</p>
            <p>Grupa Azoty Kędzierzyn</p>
            <p>Grupa Azoty Tarnów</p>
            <p>Grupa Azoty Włocławek</p> */}
          </div>
        </div>
        <section>
          <h1>Nasze produkty</h1>
          <br />
          <div className="flexBoxRow">{renderProducts()}</div>
          <br />
          <button className="moreButton">
            <NavLink to="/cennik">Więcej</NavLink>
          </button>

          <br />
        </section>
      </div>
    </div>
  );
};

export default Home;
