import Banner from "../banner/banner";

const Contact = () => {
  return (
    <div style={{ height: "90vh" }}>
      <Banner name="Kontakt" />
    </div>
  );
};

export default Contact;
