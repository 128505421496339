import "./boxproduct.css";
import fIcon from "./fIcon.png";
const BoxProduct = (props) => {
  if (props.name.length < 12) {
    return (
      <div className="boxProduct">
        <img src={fIcon} alt="Grupa Azoty" />
        <p className="boxName">{props.name}</p>
      </div>
    );
  }
  return null;
};

export default BoxProduct;
