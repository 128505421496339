import React, { useContext } from "react";
import "./map.css";
import { Theme } from "../../../context/theme";
import Banner from "../../banner/banner";

const Map = () => {
  const { isDarkTheme, lightTheme, darkTheme } = useContext(Theme);
  const theme = isDarkTheme ? darkTheme : lightTheme;
  return (
    <div
      className="text-center"
      style={{ color: theme.text, backgroundColor: theme.background }}
    >
      <Banner name="Dojazd" />
      <div>
        <br />
        <h2>Dojazd w Google Maps</h2>
        <br />
        <a
          href="https://goo.gl/maps/H6qXKvRksrwtMufR7"
          target="_blank"
          rel="noopener noreferrer"
          className="clickn"
        >
          Kliknij mnie!
        </a>
      </div>
      <br />
      <br />
      <div className="map-responsive">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55363.80336133508!2d21.675082661752374!3d51.19447996153943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf881be3c8456d857!2zNTHCsDExJzMzLjciTiAyMcKwNDInMDcuMiJF!5e0!3m2!1sen!2spl!4v1676916654017!5m2!1sen!2spl"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
