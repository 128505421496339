import React, { createContext } from "react";
import { useState } from "react";

export const Theme = createContext();

class ThemeProvider extends React.Component {
  state = {
    isDarkTheme: false,
    lightTheme: {
      text: "black",
      background: "white",
      backgroundLight: "rgb(241, 241, 241)",
    },
    darkTheme: {
      text: "white",
      background: "#5c5c5c",
      backgroundLight: "rgb(105, 105, 105)",
    },
  };

  changeTheme = () => {
    this.setState({ isDarkTheme: !this.state.isDarkTheme });
  };
  render() {
    return (
      <Theme.Provider value={{ ...this.state, changeTheme: this.changeTheme }}>
        {this.props.children}
      </Theme.Provider>
    );
  }
}

export default ThemeProvider;
//
