import React from "react";
import "./banner.css";

export default function Banner(props) {
  return (
    <div className="banner">
      {props.name}
      {props.sub ? <div className="openText">Otwarte do 20:00</div> : ""}
    </div>
  );
}
