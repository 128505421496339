import React, { useContext } from "react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Link,
  Switch,
} from "react-router-dom";
import Home from "./components/home";
import Navbar from "./components/nav/navbar";

import Cennik from "./components/subpages/cennik/cennik";
import Map from "./components/subpages/map/map";
import Footer from "./components/footer/footer";
import "./app.css";

import ThemeProvider from "./context/theme";
import Gallery from "./components/gallery/gallery";
import Contact from "./components/contact/contact";

function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <Router>
          <Navbar />
          <div className="mid">
            {" "}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/cennik" element={<Cennik />} />
              <Route path="/map" element={<Map />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </Router>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
