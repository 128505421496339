import React, { useState, useContext } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import "./navbar.css";
import icon from "./icon.png";

import { Theme } from "../../context/theme";

const Navbar = () => {
  const { isDarkTheme, lightTheme, darkTheme, changeTheme } = useContext(Theme);
  const theme = isDarkTheme ? darkTheme : lightTheme;
  const [classIS, classSet] = useState("topnav");
  const [disp, setDisp] = useState("block");

  const classChange = () => {
    if (classIS == "topnav responsive") {
      classSet("topnav");
      setDisp("block");
    } else if (classIS == "topnav") {
      classSet("topnav responsive");
      setDisp("none");
    }
  };
  return (
    <div>
      <div className={classIS} id="myTopnav">
        <NavLink to="/">Strona Główna</NavLink>
        <NavLink to="/cennik">Produkty</NavLink>
        <NavLink to="/map">Dojazd</NavLink>
        <NavLink to="/gallery">Galeria</NavLink>
        <NavLink to="/contact">Kontakt</NavLink>

        <div className="icons">
          <button className={`prod ${disp}`} onClick={changeTheme}>
            ☀
          </button>
          <a className="icon" onClick={classChange}>
            <span />
            <span />
            <span />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
